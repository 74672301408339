<template>
	<div class="">
		
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" :title="$t('i18nn_79de6d14b424271b')" append-to-body :visible.sync="dialogFormVisible" custom-class="myFullDialog4"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_79de6d14b424271b')" append-to-body :visible.sync="dialogFormVisible" :direction="'rtl'" size="1200px">
			<el-form ref="form" :rules="formRules" inline :size="'small'" :model="form" label-width="150px" style="width: 1000px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
				
				<el-form-item :label="'lgsKeyCode'" prop="" required>
					<!-- <el-input disabled :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.lgsKeyCode"></el-input> -->
					<el-select filterable clearable v-model="form.lgsKeyCode" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;" @change="lgsCodeChange">
						<!-- <el-option v-for="item in selectOption.wh_goods_fee_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option> -->
					
						<!-- <el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option> -->
						<!-- <el-option :label="'I_UPS_91702_HYTX'" :value="'I_UPS_91702_HYTX'"></el-option> -->
						<el-option
						  v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index" :label="item.logistics+'('+item.codeName+')'" :value="item.code">
								<div style="display: flex; justify-content: space-between;">
									<div>{{ item.logistics }}({{ item.codeName }})</div>
									<div style="color: #8492a6; font-size: 13px">
										<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
										<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
									</div>
								</div>
						</el-option>
					</el-select>
				</el-form-item>
					
				<el-form-item :label="$t('i18nn_e020fbe62b0c9833')" prop="" required>
					<!-- <el-select filterable clearable v-model="form.channel" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
						<el-option :label="'I_UPS_08873_HYTX'" :value="'I_UPS_08873_HYTX'"></el-option>
						<el-option :label="'I_UPS_91702_HYTX'" :value="'I_UPS_91702_HYTX'"></el-option>
					</el-select> -->
					<el-select filterable clearable v-model="form.channel" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
						<!-- <el-option v-for="item in selectOption.wh_goods_fee_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option> -->
					
						<el-option v-for="(item,index) in selectOption.channelList" :key="index" :label="item.name" :value="item.key"></el-option>
						<!-- <el-option :label="'I_UPS_91702_HYTX'" :value="'I_UPS_91702_HYTX'"></el-option> -->
					</el-select>
				</el-form-item>
				
				<!-- <el-form-item :label="$t('i18nn_590686fdff40723b')" prop="">
					<el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.relationNo"></el-input>
				</el-form-item> -->
				<el-card style="" v-if="selLgsData && selLgsData.descJson && selLgsData.descJson.length>0">
					<div slot="header" class="">
						<div style="display: flex;">
							<div>{{ selLgsData.logistics }}({{ selLgsData.codeName }})</div>
							<div style="color: #8492a6; font-size: 13px">
								<span v-if="0 == selLgsData.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
								<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
							</div>
						</div>
					</div>
					<div>
						<el-descriptions border size="mini" :labelStyle="{ 'background': '#ecf5ff'}">
							<el-descriptions-item :label="item2.name" v-for="(item2, index2) in selLgsData.descJson" :key="index2">{{ item2.value }}</el-descriptions-item>
						</el-descriptions>
					</div>
				</el-card>
				<el-card>
					<div slot="header">
						<span>{{$t('i18nn_ab546359d111c7f4')}}</span>
					</div>
					<div>
						<ExpressSheetListImportTem ref="ExpressSheetListImportTem" @parsingSuccess="parsingSuccess" :excelDataDto="excelDataDto"></ExpressSheetListImportTem>
					</div>
				</el-card>
				
				<el-card style="">
					<div slot="header" class="clearfix">
						<span>{{$t('i18nn_97e49d00bcc4c17c')}}</span>
						<!-- <el-button type="success" icon="el-icon-plus" size="small" @click="addConfSubListAction">{{$t('i18nn_24c8f46012a25c89')}}</el-button> -->
					</div>
					<div>
						<el-form-item :label="$t('i18nn_19252a10a7c4755e')" prop="">
							<el-select filterable clearable v-model="form.labelSize" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
								<el-option v-for="item in selectOption.wh_label_size" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item :label="$t('i18nn_7e80cfa637ec486b')" prop="">
							<el-select filterable clearable v-model="form.signature" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
								<el-option v-for="(item,index) in selectOption.wh_signature_service" :key="index" :label="item.name" :value="item.key"></el-option>
							</el-select>
						</el-form-item>
						
				
						<!-- <el-form-item :label="'P.O.NO'" prop=""><el-input size="mini" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.chargeType"></el-input></el-form-item> -->
						<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
							<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit></el-input>
						</el-form-item> -->
					</div>
				</el-card>
				<!-- <h3 class="titSty1">{{$t('i18nn_8200a25ab641ba97')}}</h3> -->
				<!-- <el-form-item :label="$t('i18nn_8c49a4bfd3a78dc2')" prop="" required>
					<div>
						<RechargeRecordFileUpload ref="RechargeRecordFileUpload" :openTime="FileUploadOpenTime"  @success="FileUploadSuccess"></RechargeRecordFileUpload>
					</div>
				</el-form-item> -->

				<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit style="width: 300px;"></el-input>
				</el-form-item> -->
			</el-form>
			<!-- <div>
        <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
        <el-button type="primary" style="width: 220px;" @click="submitForm('form', '1')" v-else>{{$t('i18nn_0ddb67d8d6d01ad4')}}</el-button>
      </div> -->
			<div class="drawer-footer">
				<!-- <el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button> -->
				<el-button type="primary" @click="submitAction()">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>
		<!-- </el-dialog> -->

		
	</div>
</template>
<script>
// import JQ from 'jquery'
// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import barcode from '@/components/Common/barcode.vue';
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
// import RechargeRecordFileUpload from '@/components/StorageCenter/components/RechargeRecordFileUpload.vue';
import ExpressSheetListImportTem from './ExpressSheetListImportTem.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	components: {
		//   CommodityCateLinkage,
		// barcode,
		// SelAgentUser
		// RechargeRecordFileUpload
		ExpressSheetListImportTem
	},
	props: {
		openTime: {
			// default: function() {
			//   return '';
			// },
			// type: String
		},
		// row:{},
		// mobile:"",
		// isSel: {
		// 	default: function() {
		// 		return false;
		// 	},
		// 	type: Boolean
		// },
		// status: {
		// 	default: function() {
		// 		return '';
		// 	},
		// 	type: String
		// }
	},
	data() {
		return {
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: '今天',
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: '昨天',
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: '一周前',
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			// UserInfo: this.$store.getters.getUserInfo,
			dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改
			
			excelDataDto:[],

			//导入excel
			// dialogUploadVisible: false,
			// loadingExcel: false,
			// fileExcel: '',
			// excelData: [],
			// excelFileName: '',
			fileRelationId:'',
			FileUploadOpenTime:'',

			//图片放大
			dialogImgVisible: false,
			imgUrlBigShow: '',

			CateValue: [],
			loading: false,
			
			selLgsData:{},
			form: {
					"lgsKeyCode":"",
					"channel":"",
					"labelSize":"",
					"signature":"",
					"orders":[]
			},

			formRules: {
				// amt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// remark: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
			},
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: {
				current_page: 1,
				page_sizes: [5, 10, 20, 30, 100, 200, 500],
				page_size: 10,
				total: 0
			},
			selectOption: {
				// wh_weight_unit: [],
				// wh_vol_unit: [],
				// wh_goods_fee_type:[],
				// wh_size_unit: [],
				wh_label_size: [],
				biz_wh_lgskey_code:[],
				channelList:[],
				wh_signature_service:[],
				// statusList: [
				//   {
				//     value: '',
				//     label: '全部'
				//   },
				//   {
				//     value: '10',
				//     label: '已提交'
				//   },
				//   {
				//     value: '20',
				//     label: '确认入账'
				//   }
				  
				// ]
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				agentUser: '',
				status:'',
				// "accountPeriod":"",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				// goodsSku: '',
				// // hashCode: '',
				// goodsName: '',
				// goodsNameEn: '',
				keyword: ''
				// declareNameCh: '',
			}
		};
	},
	watch: {
		openTime: function(newVal, oldVal) {
			console.log('openTime',newVal);
			// if(newVal){
				this.dialogFormVisible = true;
				this.initData();
			// }
			
		}
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		this.getDicData();
		this.getLGSByCodeData();
		// this.initData();
	},
	methods: {
		initData() {
			// this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			// this.getPageData();
			// this.getDicData();
			// if(this.row && this.row.id){
			// 	this.openDioalog(this.row, this.$t('FormMsg.Edit'));
			this.excelDataDto = [];
			// } else {
				this.openDioalog(null);
			// }
			
		},
		parsingSuccess(data){
			console.log('parsingSuccess',data);
			// this.form.expressDto = data;
			this.form.orders = data;
		},
		// changeAgentUser(val){
		// 	this.filterData.agentUser = val;
		// 	this.initData();
		// },

		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		openDioalog(formParm) {
			// console.log(formParm);
			this.dialogFormVisible = true;
			// let form = Object.assign({}, formParm);
			// console.log('form', form);
			// // 重置
			this.resetForm('form');
			// if (null === formParm) {
			// 	//新增

			// 	this.dialogFormStatus = 0;
			// 	// form.subUserId = null;
			// 	// form.userSubUserId = null;
			// 	// form.state = true;
			// 	//浅拷贝、对象属性的合并
			// 	// this.form = form;
			// } else {
			// 	//修改
			// 	// this.dialogFormStatus = 1;
			// 	// form.state = form.state === '0' ? true : false;
			// 	// this.form.goodsSubjectId = this.CateValue[0];
			// 	// this.CateValue = [form.goodsSubjectId];
			// 	//浅拷贝、对象属性的合并
			// 	// this.form = form;
			// }
			// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//       type: 'warning',
			//       //confirmButtonText: '确定',
			//     });
		},
		//打开编辑
		// openEdit(event, row, index) {
		// 	event.stopPropagation();
		// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
		// },

		//删除
		// delAction(event, row) {
		// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
		// 		// confirmButtonText: '确定',
		// 		// cancelButtonText: '取消',
		// 		type: 'warning'
		// 	})
		// 		.then(() => {
		// 			// this.$message({
		// 			//   type: 'success',
		// 			//   message: '删除成功!'
		// 			// });
		// 			this.delDataAction(event, row);
		// 		})
		// 		.catch(() => {
		// 			// this.$message({
		// 			//   type: 'info',
		// 			//   message: '已取消删除'
		// 			// });
		// 		});
		// },
		//删除
		// delDataAction(event, row) {
		// 	event.stopPropagation();
		// 	console.log('delDataAction', row);

		// 	this.postData(this.$urlConfig.WhRechargeVerifyDelete + '/' + row.id, {}, 'put');
		// },

		// hyUpLoadImg1: function(childValue) {
		//   // childValue就是子组件传过来的值
		//   this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },
		//商品类目变化
		// CateChang(v) {
		//   // this.onChangeNoSave(true);

		//   this.CateValue = v;

		//   this.form.goodsSubjectId = this.CateValue[0];

		//   // this.baseInfo.commodityCategories.first = this.CateValue[0];
		//   // this.baseInfo.commodityCategories.second = this.CateValue[1];
		//   // this.baseInfo.commodityCategories.third = this.CateValue[2];

		//   //该类目商品基本属性
		//   // this.getCommodityAttribute();
		// },
		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert('仅"已出账"状态的数据进行"确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: '确定',
		//       });
		// },
		//附件上传成功回调
		// FileUploadSuccess(data){
		// 	console.log('FileUploadSuccess',data);
			
		// 	this.form.evidenceList = data.map(item=>{
		// 		return {
		// 			fileName: item.fileName, //"文件名称",
		// 			url: item.filePath//"地址"
		// 		}
		// 	});
			
		// 	this.submitForm('form');
			
		//   // this.initData();
		//   // event.stopPropagation();
		//   // this.isShowFileUpload = true;
		//   // this.fileRelationId = row.id;
		//   // this.FileUploadOpenTime = new Date().getTime();
		// },
		//提交
		submitAction(){
			this.submitForm('form');
			// this.$nextTick(() => {
			//   try {
			//     this.$refs.RechargeRecordFileUpload.submitUpload();
			//   } catch (err) {
			//     console.log(err);
			//   }
			// });
		},
		//提交信息
		submitForm(formName) {
			if(this.selLgsData && 0 == this.selLgsData.isUse){
				this.$alert('当前选择的KeyCode为不可用状态', this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: '确定',
				});
				return;
			}
			
			this.$refs[formName].validate(valid => {
				if (valid) {
					let formData = Object.assign({}, this.form);
					formData.client = this.$store.state.client;
					//浅拷贝、对象属性的合并
					// if (0 === this.dialogFormStatus) {
						// formData.id = null;
						// formData.userId = this.UserInfo.id;

						this.postData(this.$urlConfig.WhFuerthertryExcelSave, formData);
					// } else {
					// 	// formData.userId = this.UserInfo.id;
					// 	this.postData(this.$urlConfig.WhRechargeVerifyUpdate, formData);
					// }
				} else {
					console.log('error submit!!');
					this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: '确定',
					});
					return false;
				}
			});
		},
		//重置输入框
		resetForm(formName) {
			console.log(formName);
			// console.log(this.$refs[formName]);

			if (this.$refs[formName]) {
				this.$refs[formName].resetFields();
			} else {
				console.log('this.$refs[formName]', this.$refs[formName]);
			}
			
			// this.$nextTick(() => {
			//   try {
			//     this.$refs.RechargeRecordFileUpload.initData();
			//   } catch (err) {
			//     console.log(err);
			//   }
			// });
			
			// this.form.lgsKeyCode = "Furthertry";
			// this.form.labelSize = '1';
			
			// this.form.orders = [];
			
			this.form = {
					"lgsKeyCode":"",
					"channel":"",
					"labelSize":"1",
					"signature":"",
					"orders":[]
			}
			// this.form.amt = 0;
			// this.CateValue = [];
			// if (this.$refs.hyUpLoadImg1) {
			//   this.$refs.hyUpLoadImg1.initUrl('');
			// }
		},

		//提交信息
		postData(url, formData, type) {
			// let _this = this;
			this.loading = true;
			this.loading_load = true;
			let HttpType = {};
			if ('delete' == type) {
				HttpType = this.$http.delete(url, formData);
			} else {
				HttpType = this.$http.put(url, formData);
			}
			// formData.state = formData.state ? '0' : '1';
			HttpType.then(({ data }) => {
				console.log(this.$t('tips.submitSuccess'));
				console.log(data);
				// this.$vux.loading.hide();
				this.loading = false;
				this.loading_load = false;
				if (200 == data.code) {
					this.dialogFormVisible = false;
					// this.getPageData();
					
					// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
					//   type: 'success',
					//   //confirmButtonText: '确定',
					// });
					// this.$message.success(this.$t('tips.submitSuccess'));
					if ('delete' == type) {
						this.$message({
							type: 'success',
							message: this.$t('tips.successfullyDelete')
						});
					} else {
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	type: 'success'
						// });
						this.$message.success(this.$t('tips.submitSuccess'));
					}
					
					this.$emit('postSuccess')
					
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: '确定',
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: '确定',
				});
			});
		},
		// clearFile(){
		// 	this.fileExcel = null;
		// 	this.excelFileName = '';
		// 	// this.excelFileName = "";
		// 	this.$nextTick(() => {
		// 		try {
		// 			this.$refs.file.value = '';
		// 		} catch (e) {
		// 			console.log(e);
		// 			//TODO handle the exception
		// 		}
		// 	});
		// },
		//打开导入弹窗
		// openExcelDialog(){
		//   this.dialogUploadVisible = true;
		//   this.excelData = [];
		//   this.clearFile();
		// },
		// fileChange(e) {
		//   // this.loading = true;
		//   console.log(e);

		//   console.log("文件",this.$refs.file);
		//   this.excelData = [];
		//   this.fileExcel = null;
		//   this.excelFileName = "";
		//   if (!this.$refs.file || !this.$refs.file.files) {
		//     this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
		//       // //confirmButtonText: '确定',
		//     });
		//     return;
		//   }
		//   if (this.$refs.file.files[0]) {
		//     // if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
		//       if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
		//         this.fileExcel = this.$refs.file.files[0];
		//         this.excelFileName = this.fileExcel.name;
		//         // console.log("文件",this.$refs.file);
		//         // this.uploadExcel();
		//       } else {
		//         this.$alert(this.$t('module.upload.uploaded_over')+'10M', this.$t('tips.tipsTitle'), {
		//         });
		//       }
		//     // } else {
		//     //   this.$alert(this.$t('未知的文件格式'), this.$t('tips.tipsTitle'), {
		//     //   });
		//     // }
		//   } else {
		//     // this.$alert('文件读取失败', this.$t('tips.tipsTitle'), {
		//     // });
		//   }
		// },

		//  openExcel(){
		// // this.initExcelData();
		//    // this.$nextTick(()=>{
		//      try{
		//        this.$refs.file.value = '';
		//      }catch(e){
		//        console.log(e);
		//        //TODO handle the exception
		//      }
		//    // });
		//    // this.$refs.file.dispatchEvent(new MouseEvent('click'));
		//    var comment = this.$refs.file;
		//    if (document.all) {
		//      // For IE
		//      comment.click();
		//    } else if (document.createEvent) {
		//      // 在ff中要为a标签添加click事件，并且侦听该事件
		//      var ev = document.createEvent('MouseEvents');
		//      ev.initEvent('click', false, true);
		//      comment.dispatchEvent(ev);
		//    } else {
		//      // this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
		//      this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
		//        // //confirmButtonText: '确定',
		//      });
		//    }
		//  },
		//导入excel
		// uploadExcel(){
		//   if(!this.fileExcel){
		//     this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {
		//     });
		//     return;
		//   }

		//   this.loadingExcel = true;

		//   let file = this.fileExcel;
		//   var formdata = new FormData();
		//   formdata.append("file",file);

		//   this.$http.post(this.$urlConfig.WhMyGoodsSkuExUpload, formdata,{
		//     headers: {
		//       'Content-Type': 'multipart/form-data'
		//     }
		//   })
		//     .then(({ data }) => {
		//       console.log(this.$t('tips.submitSuccess'));
		//       console.log(data);
		//       this.loadingExcel = false;
		//       if (200 == data.code && data.rows) {
		//         this.excelData = data.rows;
		//         // if('1'==data.data.flag){
		//         //   this.excelData = data.data.data;
		//         // } else {
		//         //   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		//         //     type: 'warning',
		//         //     //confirmButtonText: '确定',
		//         //   });
		//         // }
		// 		this.clearFile();
		//       } else {
		//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//           type: 'warning',
		//           //confirmButtonText: '确定',
		//         });
		//       }
		//       // if (200 == data.code && data.data) {
		//       //   if('1'==data.data.flag){
		//       //     this.excelData = data.data.data;
		//       //   } else {
		//       //     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		//       //       type: 'warning',
		//       //       //confirmButtonText: '确定',
		//       //     });
		//       //   }

		//       // } else {
		//       //   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//       //     type: 'warning',
		//       //     //confirmButtonText: '确定',
		//       //   });
		//       // }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log(this.$t("tips.submitError"));
		//       this.loadingExcel = false;
		//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: '确定',
		//       });
		//     });
		// },
		//提交-导入excel
		// submitExcelForm(){
		//   this.loadingExcel = true;
		//   this.$http.put(this.$urlConfig.WhMyGoodsSkuExSubmit, this.excelData)
		//     .then(({ data }) => {
		//       console.log(this.$t('tips.submitSuccess'));
		//       console.log(data);
		//       this.loadingExcel = false;
		//       if (200 == data.code && data.data) {
		//         if('1'==data.data.flag){
		//           // this.excelData = data.data.data;
		//           this.dialogUploadVisible = false;
		//           this.excelData = [];
		//           this.excelFileName = "";
		//           this.fileExcel = null;

		//           this.initData();
		//           // this.$alert("保存成功", this.$t('tips.tipsTitle'), {
		//           //   type: 'success',
		//           //   //confirmButtonText: '确定',
		//           // });
		//           this.$message.success(this.$t('tips.submitSuccess'));
		//         } else {
		//           this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
		//             type: 'warning',
		//             //confirmButtonText: '确定',
		//           });
		//         }

		//       } else {
		//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//           type: 'warning',
		//           //confirmButtonText: '确定',
		//         });
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log(this.$t("tips.submitError"));
		//       this.loadingExcel = false;
		//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: '确定',
		//       });
		//     });
		// },
		//查询数据
		// serPageData() {
		// 	this.pagination.current_page = 1;
		// 	this.getPageData();
		// },
		//商品类目变化
		// CateChang2(v) {
		//   // this.onChangeNoSave(true);

		//   // this.CateValue = v;

		//   this.filterData.subjectName = this.CateValue[0];
		//   this.serPageData();
		//   // this.baseInfo.commodityCategories.first = this.CateValue[0];
		//   // this.baseInfo.commodityCategories.second = this.CateValue[1];
		//   // this.baseInfo.commodityCategories.third = this.CateValue[2];

		//   //该类目商品基本属性
		//   // this.getCommodityAttribute();
		// },
		//请求分页数据
		// getPageData() {
		// 	// let _this = this;
		// 	this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.WhRechargeVerifyPageList, {
		// 			sortAsc: this.filterData.sortAsc,
		// 			orderBy: this.filterData.orderBy,
		// 			offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
		// 			limit: this.pagination.page_size, //当前页显示数目
					
		// 			verfiyStatus: this.filterData.status? this.filterData.status:null,
		// 			// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
		// 			//      // status: this.filterData.status ? this.filterData.status : null,
		// 			//      subjectName: this.filterData.subjectName ? this.filterData.subjectName : null,
		// 			//      goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
		// 			//      // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
		// 			//      goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
		// 			//      goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
		// 			//      keyword: this.filterData.keyword ? this.filterData.keyword : null,
		// 			// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
		// 		})
		// 		.then(({ data }) => {
		// 			// console.log(this.$store.getters.getUserInfo);
		// 			console.log('分页，请求成功');
		// 			console.log(data);
		// 			// this.$vux.loading.hide();
		// 			this.loading_load = false;
		// 			//表格显示数据
		// 			this.tableData = data.rows;
		// 			//当前数据总条数
		// 			this.pagination.total = parseInt(data.total);
		// 			//当前页数
		// 			// this.pagination.current_page = parseInt(data.current);
		// 			//当前页条数
		// 			// this.pagination.page_size = parseInt(data.size);
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('分页，请求失败');
		// 			this.loading_load = false;
		// 		});
		// },
		//操作
		// sureBillAction(row,msg,state) {
		//   console.log('确认', row);
		//   console.log(row);
		//   this.$confirm('确定要'+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
		//     //confirmButtonText: '确定',
		//     //cancelButtonText: '取消',
		//     type: 'warning'
		//   }).then(() => {
		//     //确认
		//     let dataParm = {
		//       ids:[row.id],
		//       state:state,
		//     }
		//     this.updatePageData(dataParm, msg);
		//   }).catch(() => {

		//   });

		// },
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//选择数据后回调
		// selRow(event, row) {
		// 	event.stopPropagation();
		// 	this.$emit('selectRow', row);
		// },
		lgsCodeChange(key){
			this.form.channel = "";
			this.form.signature = "";
			this.selLgsData = this.selectOption.biz_wh_lgskey_code.find(item=>{
				return key==item.code;
			});
			// this.getChanneByCodeData(key);
			if (!!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.channels) {
				this.selectOption.channelList = this.selLgsData.extendParamJson.channels;
			} else {
				this.selectOption.channelList = [];
			}
			//签名
			if (!!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.signatures) {
				this.selectOption.wh_signature_service= this.selLgsData.extendParamJson.signatures;
			} else {
				this.selectOption.wh_signature_service = [];
			}
		},
		//渠道代码值
		// getChanneByCodeData(key){
		// 	console.log(key);
		// 	this.loading = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, [key])
		// 		.then(({ data }) => {
		// 			// console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			this.loading = false;
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.channelList = data.data[key];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.errorData');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			this.loading = false;
		// 			// console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.requestErrorData'));
		// 		});
		// },
		//lgscode
		getLGSByCodeData(){
			this.loading = true;
			this.$http
				.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
					isUse:'1'
				})
				.then(({ data }) => {
					console.log('查询lgs，请求成功');
					console.log(data);
					this.loading = false;
					if (200 == data.code && data.rows) {
						this.selectOption.biz_wh_lgskey_code = data.rows;
					} else {
						if (!data.msg) {
							data.msg = this.$t('tips.queryErrorFailed');
						}
						this.$message.warning(data.msg);
					}
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
					console.log('查询数据字典接口，请求失败');
					this.$message.error(this.$t('tips.queryRequestFailed'));
				});
		},
		//查询数据字典
		getDicData() {
			// let _this = this;
			// console.log(keyword);
		
			// this.loading_load = true;
			this.$http
				.put(this.$urlConfig.HyDicQueryList, ['wh_label_size'])
				.then(({ data }) => {
					console.log('查询数据字典，请求成功');
					console.log(data);
					if (200 == data.code && data.data) {
						// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
						// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
						this.selectOption.wh_label_size = data.data['wh_label_size'];
						// this.selectOption.wh_signature_service = data.data['wh_signature_service'];
						// this.selectOption.biz_wh_lgskey_code = data.data['biz_wh_lgskey_code'];
						// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
					} else {
						if (!data.msg) {
							data.msg = this.$t('tips.errorData');
						}
						this.$message.warning(data.msg);
					}
				})
				.catch(error => {
					console.log(error);
					console.log('查询数据字典接口，请求失败');
					this.$message.error(this.$t('tips.requestErrorData'));
				});
		}
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
</style>
