<template>
	<div>
		<el-drawer :wrapperClosable="false" :title="dialogFormVisibleMsg" append-to-body :visible.sync="dialogFormVisible"
			:direction="'rtl'" size="1200px">
			<el-form ref="form" :inline="true" :rules="formRules" :size="'small'" :model="form" label-width="150px"
					style="width: 1160px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
					<!-- <el-card class=""> -->
					<!-- <div slot="header">
							<span>{{ 'undefined' }}</span>
						</div> -->
					<div>
						<el-form-item :label="'lgsKeyCode'" prop="" required>
							<el-select filterable clearable v-model="form.lgsKeyCode" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;" @change="lgsCodeChange">
								<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
									:label="item.logistics+'('+item.codeName+')'" :value="item.code">
									<div style="display: flex; justify-content: space-between;">
										<div>{{ item.logistics }}({{ item.codeName }})</div>
										<div style="color: #8492a6; font-size: 13px">
											<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
											<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
										</div>
									</div>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="$t('i18nn_e020fbe62b0c9833')" prop="" required>
							<div v-if="3==dialogFormVisibleType">
								<el-switch
								  style="display: block"
								  v-model="allChannel"
									:active-value="true"
									:inactive-value="false"
								  :active-text="$t('i18nn_16853bda54120bf1')"
								  :inactive-text="$t('i18nn_7a22cefca1bca21d')">
								</el-switch>
								<div v-if="allChannel">
									<el-tag v-for="(item,index) in allChannelList" :key="index">
										{{ item.name }}
									</el-tag>
								</div>
								<el-select filterable clearable v-model="form.channel" :placeholder="$t('i18nn_2ad108ab2c560530')"
									style="width: 200px;" v-if="!allChannel">
									<el-option v-for="(item,index) in selectOption.channelList" :key="index" :label="item.name"
										:value="item.key">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ item.name }}</span>
											<span class="sel_option_code" style="">{{ item.key }}</span>
										</div>
									</el-option>
								</el-select>
							</div>
							<div v-else>
								<el-select filterable clearable v-model="form.channel" :placeholder="$t('i18nn_2ad108ab2c560530')"
									style="width: 200px;">
									<el-option v-for="(item,index) in selectOption.channelList" :key="index" :label="item.name"
										:value="item.key">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ item.name }}</span>
											<span class="sel_option_code" style="">{{ item.key }}</span>
										</div>
									</el-option>
								</el-select>
							</div>
						</el-form-item>
						<el-form-item :label="$t('i18nn_590686fdff40723b')" prop="">
							<el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.relationNo"></el-input>
						</el-form-item>
						<el-card style="" v-if="selLgsData && selLgsData.descJson && selLgsData.descJson.length>0">
							<!-- <div slot="header" class="">
								<div style="display: flex;">
									<div>{{ selLgsData.logistics }}({{ selLgsData.codeName }})</div>
									<div style="color: #8492a6; font-size: 13px">
										<span v-if="0 == selLgsData.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
										<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
									</div>
								</div>
							</div> -->
							<div>
								<el-descriptions border size="mini" :labelStyle="{ 'background': '#ecf5ff'}">
									<el-descriptions-item :label="item2.name" v-for="(item2, index2) in selLgsData.descJson"
										:key="index2">{{ item2.value }}</el-descriptions-item>
								</el-descriptions>
							</div>
						</el-card>
						
						<!-- 限制发货地址 -->
						<el-card :title="$t('i18nn_2bfaf322a81c4e8c')" style="">
							<div slot="header" class=""><span>{{$t('i18nn_f1e789e4700e15e3')}}</span></div>
							<el-radio-group v-model="selCopeShip" @change="ChangCopeShip">
								<div>
									<el-radio :label="item.name" v-for="(item,index) in CopeShipfromList"
										:key="index">{{item.name}}</el-radio>
								</div>
							</el-radio-group>
							<div v-if="form.senderVo && selCopeShip" style="line-height: 200%; font-size: 14px; color:#E6A23C;">
								<span>{{form.senderVo.firstname}},</span>
								<span v-if="form.senderVo.lastname">{{form.senderVo.lastname}},</span>
								<span>({{form.senderVo.mobile}}),</span>
								<span v-if="form.senderVo.mail">({{form.senderVo.mail}}),</span>
								<span v-if="form.senderVo.company">{{form.senderVo.company}},</span>
								<span>{{form.senderVo.addr1}},</span>
								<span v-if="form.senderVo.addr2">{{form.senderVo.addr2}},</span>
								<span>{{form.senderVo.city}},</span>
								<span>{{form.senderVo.province}},</span>
								<span>{{form.senderVo.postcode}},</span>
								<span>{{form.senderVo.country}}</span>
							</div>
							<div style="margin: 5px 0;">
								<el-checkbox v-model="isComparePrice">自定义发件人地址(仅比价时可自定义)</el-checkbox>
							</div>
						</el-card>
						
						<el-card style="" v-if="isComparePrice">
							<div slot="header" class="">
								<span>{{$t('i18nn_479ea0356ec72989')}}</span>
								<span>(仅比价时可自定义)</span>
							</div>
							<div>
								<el-form-item :label="$t('i18nn_2dfd6348829d984f')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.firstname"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_e03f8082906c4de8')" prop=""><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.lastname"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_ab653780491c0d4b')" prop=""><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.company"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr1"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop=""><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr2"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.city"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_aa2bc6dc3cafeb11')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.province"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.country"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.postcode"></el-input></el-form-item>
									
								<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="" required><el-input 
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.mobile"></el-input></el-form-item>
								<el-form-item :label="'mail'" prop=""><el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.mail"></el-input></el-form-item>
							</div>
						</el-card>
						<!-- 发件人信息 -->
						<!-- <el-card style="">
							<div slot="header" class="">
								<div style="display: flex; justify-content: space-between;">
									<div>
										<div>{{$t('i18nn_479ea0356ec72989')}}</div>
									</div>
								</div>
							</div>
									
							<div>
								<el-form-item :label="$t('i18nn_2dfd6348829d984f')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.firstname"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_e03f8082906c4de8')" prop=""><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.lastname"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_ab653780491c0d4b')" prop=""><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.company"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr1"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop=""><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.addr2"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.city"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_aa2bc6dc3cafeb11')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.province"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required><el-input disabled
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.country"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.postcode"></el-input></el-form-item>
									
								<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.senderVo.mobile"></el-input></el-form-item>
								<el-form-item :label="'mail'" prop=""><el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.senderVo.mail"></el-input></el-form-item>
							</div>
						</el-card> -->
						
						<!-- 收件人信息 -->
						<el-card style="">
							<div slot="header" class="clearfix">
								<div style="display: flex; justify-content: space-between;">
									<div>
										<div>{{$t('i18nn_4851293ecdd539ed')}}</div>
									</div>
									<div style="display: flex;">
										<div style="">
											<span>FBA仓库编号</span>
											<el-input size="small" clearable v-model="fbaAddrCode" style="width:200px;">
												<el-button slot="append" type="primary" icon="el-icon-search"
													@click="searchFbaAddress(1)"></el-button>
											</el-input>
										</div>
										<div>
											<span>HYTX仓库</span>
											<whNoAllSelect ref="whNoAllSelect" @changeData="changWhNo" @loadAllSuccess="whLoadSuccess">
											</whNoAllSelect>
										</div>
									</div>
								</div>
							</div>
			
							<div>
								<el-form-item :label="$t('i18nn_caed6cfc34b968aa')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.firstname"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_f9c0ee1156f1e254')" prop=""><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.lastname"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_ab653780491c0d4b')" prop=""><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.company"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_8db45b615b72b7a8')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.receiverVo.addr1"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_3e429a7c7cf3cab2')" prop=""><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.receiverVo.addr2"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.receiverVo.city"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_aa2bc6dc3cafeb11')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.province"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required><el-input disabled
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.country"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.postcode"></el-input></el-form-item>
			
								<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="" required><el-input
										:placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.mobile"></el-input></el-form-item>
								<el-form-item :label="'mail'" prop=""><el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')"
										v-model="form.receiverVo.mail"></el-input></el-form-item>
								<el-form-item :label="$t('i18nn_0bbce74881c893df')" prop="" required>
									<el-select filterable clearable v-model="form.receiverVo.addr_type"
										:placeholder="$t('i18nn_2ad108ab2c560530')">
										<el-option :label="$t('i18nn_6b57a5faf337b87b')" :value="'0'"></el-option>
										<el-option :label="$t('i18nn_a9cbcfc68126f75a')" :value="'1'"></el-option>
										<el-option :label="$t('i18nn_af200a78a2a44559')" :value="'2'"></el-option>
									</el-select>
			
									<el-button style="margin-left: 5px;" type="primary" icon="el-icon-discover" size=""
										@click="checkAddress">{{$t('i18nn_6a4dc4a49612041e')}}</el-button>
								</el-form-item>
							</div>
						</el-card>
						
						<!-- 包裹信息 -->
						<el-card style="">
							<div slot="header" class="clearfix"><span>{{$t('i18nn_b98aefdd52a12eee')}}</span></div>
							<div>
								<el-form-item :label="$t('i18nn_0e60303b30d5d4b4')" prop="" required><el-input-number
										v-model="form.packageVo.length" controls-position="right"></el-input-number></el-form-item>
								<el-form-item :label="$t('i18nn_6cd31871f8528dd5')" prop="" required><el-input-number
										v-model="form.packageVo.width" controls-position="right"></el-input-number></el-form-item>
								<el-form-item :label="$t('i18nn_93c9ea4a0e52c641')" prop="" required><el-input-number
										v-model="form.packageVo.height" controls-position="right"></el-input-number></el-form-item>
								<el-form-item :label="$t('i18nn_96f1a4b17e75892d')" prop="" required><el-input-number
										v-model="form.packageVo.weight" controls-position="right"></el-input-number></el-form-item>
			
								<el-form-item :label="$t('i18nn_f9db93b87e08763b')" prop="" required><el-input-number
										v-model="form.packageVo.packageCount" controls-position="right"></el-input-number></el-form-item>
								<el-form-item :label="$t('i18nn_b6bf0a07fe26f74f')" prop="" required>
									<!-- <el-input-number size="small" v-model="form.packageVo.weight" controls-position="right"></el-input-number> -->
									<!-- <el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.packageVo.sizUnit"></el-input> -->
			
									<el-select filterable clearable v-model="form.packageVo.sizeUnit"
										:placeholder="$t('i18nn_2ad108ab2c560530')">
										<el-option v-for="item in selectOption.wh_size_unit" :key="item.code"
											:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
									</el-select>
								</el-form-item>
			
								<el-form-item :label="$t('i18nn_7b16a04efec1728b')" prop="">
									<span class="red">(USD)</span>
									<el-input-number v-model="form.packageVo.insurance_value" controls-position="right"></el-input-number>
								</el-form-item>
			
			
							</div>
						</el-card>
						
						<!-- 其他信息 -->
						<el-card style="">
							<div slot="header" class="clearfix">
								<span>{{$t('i18nn_97e49d00bcc4c17c')}}</span>
								<!-- <el-button type="success" icon="el-icon-plus" size="small" @click="addConfSubListAction">{{$t('i18nn_24c8f46012a25c89')}}</el-button> -->
							</div>
							<div>
								<el-form-item :label="$t('i18nn_19252a10a7c4755e')" prop="">
									<el-select filterable clearable v-model="form.others.label_size"
										:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
										<el-option v-for="item in selectOption.wh_label_size" :key="item.code"
											:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
									</el-select>
								</el-form-item>
			
								<el-form-item :label="$t('i18nn_7e80cfa637ec486b')" prop="">
									<el-select filterable clearable v-model="form.others.signature_service"
										:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
										<el-option v-for="(item,index) in selectOption.wh_signature_service" :key="index" :label="item.name"
											:value="item.key"></el-option>
									</el-select>
								</el-form-item>
			
			
								<!-- <el-form-item :label="'P.O.NO'" prop=""><el-input size="mini" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.chargeType"></el-input></el-form-item> -->
								<el-form-item :label="$t('Storage.tableColumn.remark')">
									<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.others.remark"
										:maxlength="1000" show-word-limit></el-input>
								</el-form-item>
							</div>
						</el-card>
					</div>
			
					<!-- </el-card> -->
				</el-form>
				
				<el-card style="" v-loading="loading" v-if="3==queryFeeRateType">
					<!-- <div slot="header" class="clearfix">
						<span style="color:red; font-weight: bold;">查询试算结果</span>
					</div> -->
					<div>
						<el-descriptions title="查询试算结果">
							<el-descriptions-item label="渠道名称">{{queryFeeRateResClac.codeName}}</el-descriptions-item>
							<el-descriptions-item label="类型">{{queryFeeRateResClac.addTypeName}}</el-descriptions-item>
							<el-descriptions-item label="计算重量">{{queryFeeRateResClac.rateWeight}}</el-descriptions-item>
							<el-descriptions-item label="地址区间">{{queryFeeRateResClac.zone}}</el-descriptions-item>
						</el-descriptions>
				
						<el-table ref="multipleTable4" :data="queryFeeRateResClac.serviceArr" highlight-current-row stripe :border="true"
							style="width: 100%" size="small">
							<el-table-column type="index" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							<el-table-column prop="service" :label="'服务'"></el-table-column>
							<el-table-column prop="totalPrice" :label="'价格'"></el-table-column>
							<el-table-column prop="" :label="'明细'">
								<template slot-scope="scope">
									<div v-if="scope.row.fees">
										<div v-for="(item, index) in scope.row.fees" :key="index" style="">
											<span>{{item.feeTypeName}}</span>：<span>{{item.price}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
						</el-table>
				
					</div>
				</el-card>
				
				<div v-loading="loading" v-else-if="4==queryFeeRateType">
					<div style="padding: 10px;">
						<span style="font-size: 18px;font-weight: bold;">查询比价结果</span>
					</div>
					<el-card style="" v-for="(queryFeeRateResItem,index) in queryFeeRateResAll" :key="index">
						<!-- <div slot="header" class="clearfix">
							<span style="color:red; font-weight: bold;">查询比价结果</span>
						</div> -->
						<div>
							<el-descriptions title="">
								<el-descriptions-item label="渠道名称">{{queryFeeRateResItem.codeName}}</el-descriptions-item>
								<el-descriptions-item label="类型">{{queryFeeRateResItem.addTypeName}}</el-descriptions-item>
								<el-descriptions-item label="计算重量">{{queryFeeRateResItem.rateWeight}}</el-descriptions-item>
								<el-descriptions-item label="地址区间">{{queryFeeRateResItem.zone}}</el-descriptions-item>
							</el-descriptions>
					
							<el-table ref="multipleTable4" :data="queryFeeRateResItem.serviceArr" highlight-current-row stripe :border="true"
								style="width: 100%" size="small">
								<el-table-column type="index" fixed="left" width="50" align="center"
									:label="$t('7b1c2b1adc920d9c')"></el-table-column>
								<el-table-column prop="service" :label="'服务'"></el-table-column>
								<el-table-column prop="totalPrice" :label="'价格'"></el-table-column>
								<el-table-column prop="" :label="'明细'">
									<template slot-scope="scope">
										<div v-if="scope.row.fees">
											<div v-for="(item, index) in scope.row.fees" :key="index" style="">
												<span>{{item.feeTypeName}}</span>：<span>{{item.price}}</span>
											</div>
										</div>
									</template>
								</el-table-column>
							</el-table>
					
						</div>
					</el-card>
				</div>
			
				<el-card style="" v-loading="loading" v-else>
					<div slot="header" class="clearfix">
						<span style="color:red; font-weight: bold;">{{$t('i18nn_45b0c5bc56871f57')}}</span>
						<!-- <el-button type="success" icon="el-icon-plus" size="small" @click="addConfSubListAction">{{$t('i18nn_24c8f46012a25c89')}}</el-button> -->
					</div>
					<div>
						<!-- <div>
							<strong>价格(USD):</strong>
							<span>{{queryFeeRateRes.price}}</span>
						</div> -->
						<el-table ref="multipleTable4" :data="queryFeeRateRes" highlight-current-row stripe :border="true"
							style="width: 100%" size="small">
							<el-table-column type="index" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							<el-table-column prop="carrier" :label="'Carrier'"></el-table-column>
							<el-table-column prop="carrierServiceLevel" :label="'CarrierServiceLevel'"></el-table-column>
							<el-table-column prop="channel" :label="'Channel'"></el-table-column>
							<el-table-column prop="price" :label="'Price'"></el-table-column>
							<el-table-column prop="" :label="'Details'">
								<template slot-scope="scope">
									<div v-if="scope.row.feeDetail">
										<div v-for="(val, key, index) in scope.row.feeDetail" :key="key">
											<span>{{key}}</span>：<span>{{val}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="200px" fixed="right"
								v-if="1 == dialogFormVisibleType || 2 == dialogFormVisibleType">
								<template slot-scope="scope">
									<div v-if="scope.row && scope.row.channel">
										<el-button @click="selAndSubmit($event, scope.row, scope.$index)" type="primary" size="mini"
											icon="el-icon-tickets">{{$t('i18nn_ff89821d70424147')}}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
						<!-- <div>
							<strong>{{$t('i18nn_118a120c399e878f')}}</strong>
							<span>
								<el-input readonly :rows="5" type="textarea" v-model="queryFeeRateRes.price_detail"></el-input>
							</span>
						</div> -->
					</div>
				</el-card>
			
				<div class="drawer-footer">
					<!-- <el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button> -->
			
					<el-button type="primary" icon="el-icon-tickets"
						@click="submitForm('form', 1)" v-if="(1 == dialogFormVisibleType || 2 == dialogFormVisibleType) && !isComparePrice">
						{{ $t('FormMsg.Save') }}
					</el-button>
			
					<el-button type="warning" icon="el-icon-search"
						@click="submitForm('form', 2)" v-if="!isComparePrice">{{$t('i18nn_ea74b89efa9dbdb8')}}</el-button>
					
					<el-button type="warning" icon="el-icon-search"
						@click="submitForm('form', 3)" v-if="!isComparePrice">运费试算</el-button>
					
					<el-button type="warning" icon="el-icon-search"
						@click="submitForm('form', 4)">运费比价</el-button>
			
					<el-button @click="resetForm('form')">{{$t('i18nn_dc2f299dc3aeffb3')}}</el-button>
			
			
				</div>
			</el-drawer>

	</div>
</template>
<script>
	import {
		deepClone,
		clearObjectVal
	} from '@/utils/utils.js';

	import {
		getDicData
	} from '@/axios/common.js';
	
	import whNoAllSelect from '@/components/StorageCenter/components/whNoAllSelect.vue';

	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			openRow: {},
			type:{},
			typeTitle:{},
			copy:{}
		},
		components: {
			// cusSelFuzzy,
			// whNoSelect
			whNoAllSelect
		},
		data() {
			return {
				// dialogFormVisible: false,
				
				dialogFormVisible: false,
				dialogFormVisibleMsg: '',
				dialogFormVisibleType: '1',
				allChannel: true, //是否全部渠道
				allChannelList: [],
				
				selLgsData: {},
				
				CopeShipfromList: [],
				selCopeShip: '',
				// 发货地址不可编辑的LgsCode
				// onlySenderLgsCode: ['cope_fedex', 'cope_ups', 'cope_usps', 'paramount'],
				//仅比价可以编辑发货地址
				isComparePrice: false,
				
				form: {
					lgsKeyCode: '',
					channel: '',
					relationNo: '',
					receiverVo: {
						firstname: '',
						lastname: '',
						company: '',
						addr1: '',
						addr2: '',
						city: '',
						province: '',
						postcode: '',
						country: '',
						mobile: '',
						mail: '',
						addr_type: ''
					},
					senderVo: {
						firstname: '',
						lastname: '',
						company: '',
						addr1: '',
						addr2: '',
						city: '',
						province: '',
						postcode: '',
						country: '',
						mobile: '',
						mail: ''
					},
					packageVo: {
						weight: '',
						length: '',
						height: '',
						width: '',
						packageCount: '',
						sizeUnit: '',
						insurance_value: ''
					},
					others: {
						rateId: '',
						label_size: '',
						signature_service: '', //签名服务	不传或传0: 无签名 、1：Adult Signature 2: Direct Signature
						remark: ''
					}
				},
				
				formRules: {
					userId: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}],
					chargeType: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}]
					// feePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				},
				
				queryFeeRateRes: [],//查询费率
				queryFeeRateResClac:{},//试算
				queryFeeRateResAll:[],//比价
				
				queryFeeRateType: '',//试算类型
				
				fbaAddrCode: "", //FBA仓库地址编号
				// fbaAddrCode2: "",
				whNoList: [], //仓库列表

				loading: false,
			

				selectOption: {
					wh_weight_unit: [],
					wh_vol_unit: [],
					wh_label_size: [],
					wh_size_unit: [],
					biz_wh_lgskey_code: [],
					channelList: [],
					wh_signature_service: [],
				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFormVisible = true;
				this.initData();
				
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			//数据字典
			getDicData(['wh_weight_unit', 'wh_vol_unit', 'wh_label_size', 'wh_size_unit'],
				(data) => {
					this.selectOption.wh_weight_unit = data['wh_weight_unit'];
					this.selectOption.wh_vol_unit = data['wh_vol_unit'];
					this.selectOption.wh_label_size = data['wh_label_size'];
					this.selectOption.wh_size_unit = data['wh_size_unit'];
					// 选择时带的数据，不需要从数据字典获取
					// this.selectOption.wh_signature_service = data['wh_signature_service'];
				});
			
			this.getLGSByCodeData();
			
		},
		methods: {
			initData() {
				
				this.queryFeeRateRes= [];//查询费率
				this.queryFeeRateResClac={};//试算
				this.queryFeeRateResAll=[];//比价
				
				//发货地址列表
				this.CopeShipfromList = [];
				//默认不编辑发货地址
				this.isComparePrice = false;
				
				// // 重置
				this.resetForm('form');
				// this.form = Object.assign({}, this.openRow);
				
				this.openDioalog(this.openRow, this.typeTitle, this.type);
				// if (!!this.row) {
				// 	this.getDetData(this.row.id);
				// }
			},

			changCus2(data) {
				console.log('changCus', data);
				this.form.userId = data.userId;
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
			},
			
			//清空收件人地址
			clearReceiverVo() {
				this.form.receiverVo = clearObjectVal(this.form.receiverVo);
				this.form.receiverVo.country = 'US';
			},
			//清空发件人地址
			clearSenderVo() {
				this.form.senderVo = clearObjectVal(this.form.senderVo);
				this.form.senderVo.country = 'US';
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				// this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				let findWh = this.whNoList.find(item => {
					return item.whNo == data.code;
				});
				if (!!findWh) {
					this.form.receiverVo.province = findWh.state;
					this.form.receiverVo.city = findWh.city;
					this.form.receiverVo.addr1 = findWh.address;
					this.form.receiverVo.addr2 = "";
					this.form.receiverVo.postcode = findWh.postalCode;
					this.form.receiverVo.mobile = findWh.phoneNumber;
					this.form.receiverVo.mail = findWh.email;
					this.form.receiverVo.company = findWh.company;
					this.form.receiverVo.firstname = findWh.name;
					this.form.receiverVo.lastname = "";
				}
				// this.form.receiverVo.country = findWh.country;
			
				// company: '',
				// addr1: '',
				// addr2: '',
				// city: '',
				// province: '',
				// postcode: '',
				// country: '',
				// mobile: '',
				// mail: '',
			
			},
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	// this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// 	let findWh = this.whNoList.find(item => {
			// 		return item.whNo == data.code;
			// 	});
			// 	if (!!findWh) {
			// 		// this.form.receiverVo.country = findWh.country;
			// 		this.form.senderVo.province = findWh.state;
			// 		this.form.senderVo.city = findWh.city;
			// 		this.form.senderVo.addr1 = findWh.address;
			// 		this.form.senderVo.addr2 = "";
			// 		this.form.senderVo.postcode = findWh.postalCode;
			// 		this.form.senderVo.mobile = findWh.phoneNumber;
			// 		this.form.senderVo.mail = findWh.email;
			// 		this.form.senderVo.company = findWh.company;
			// 		this.form.senderVo.firstname = findWh.name;
			// 		this.form.senderVo.lastname = "";
			// 	}
			// 	// company: '',
			// 	// addr1: '',
			// 	// addr2: '',
			// 	// city: '',
			// 	// province: '',
			// 	// postcode: '',
			// 	// country: '',
			// 	// mobile: '',
			// 	// mail: '',
			
			// },
			whLoadSuccess(list) {
				this.whNoList = list;
			},
			
			//打开新增编辑，弹窗
			openDioalog(formParm, msg, type) {
				console.log(formParm);
				this.dialogFormVisible = true;
				this.dialogFormVisibleMsg = msg;
				this.dialogFormVisibleType = type;
				let form = Object.assign({}, formParm);
				console.log('form', form);
			
				// // 重置
				this.resetForm('form');
				// this.$nextTick(() => {
				// 	this.$refs.cusSelFuzzy2.clearData();
				// });
			
				// Object.keys(this.form).forEach(key => {
				// 	this.form[key] = null;
				// });
				if (null === formParm) {//新增
					
				} else {//编辑-查询
					
					try {
						//修改
						this.form = JSON.parse(JSON.stringify(form));
						//复制
						if(this.copy){
							this.form.id = null;
						}
						this.lgsCodeChange(this.form.lgsKeyCode);
						// this.getChanneByCodeData(this.form.lgsKeyCode)
						let selLgsData = this.selectOption.biz_wh_lgskey_code.find(item => {
							return this.form.lgsKeyCode == item.code;
						});
						//旧-渠道代码-数据字典
						// this.getChanneByCodeData(key);
			
						//渠道代码
						if (!!selLgsData && !!selLgsData.extendParamJson && !!selLgsData.extendParamJson.channels) {
							this.selectOption.channelList = selLgsData.extendParamJson.channels;
						} else {
							this.selectOption.channelList = [];
						}
						//签名
						if (!!selLgsData && !!selLgsData.extendParamJson && !!selLgsData.extendParamJson.signatures) {
							this.selectOption.wh_signature_service = selLgsData.extendParamJson.signatures;
						} else {
							this.selectOption.wh_signature_service = [];
						}
						
						this.allChannelList = [];
						if('3'==this.dialogFormVisibleType && selLgsData && selLgsData.extendParamJson){//查询
							this.allChannel = true;
							this.allChannelList = selLgsData.extendParamJson.channels;
						}
						
					} catch (err) {
						//TODO handle the exception
						console.log(err);
						this.$message.warning(this.$t('i18nn_820a3a60bc317776'))
					}
					// this.form = {
					// 	id: form.id,
					// 	userId: form.userId,
					// 	cusName: form.cusName,
					// 	chargeType: form.chargeType,
					// 	confSubList: form.confSubList,
					// 	remark: form.remark
					// };
				}
			},
			//打开编辑
			// editAction(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'), 2);
			// },
			
			//复制
			// copyAction(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'), 2);
			// },
			
			
			//运单查询，打开费率查询
			openFeeSer() {
				// event.stopPropagation();
				let row = null;
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length > 1) {
					// row = this.multipleSelection[0];
					this.$message.warning(this.$t('i18nn_b0e16646a7aa97eb') + dataList.length + "条，请选择单条查询")
					return;
				} else if (dataList.length == 1) {
					row = this.multipleSelection[0];
				}
				// event.stopPropagation();
				this.openDioalog(row, this.$t('i18nn_75087cd54a97247c'), 3);
			},
			
			//选择查询后的费率保存
			selAndSubmit(event, row, index) {
				event.stopPropagation();
				console.log('selAndSubmit', row);
				//保存指定渠道
				this.submitForm('form', 1, row);
			},
			//提交信息
			submitForm(formName, type, row) {
				if (this.selLgsData && 0 == this.selLgsData.isUse) {
					this.$alert('当前选择的LgsKeyCode为不可用状态', this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
					return;
				}
				
				this.queryFeeRateRes = [];
				this.queryFeeRateResClac = {};
				this.queryFeeRateResAll = [];
				this.queryFeeRateType = type;
				
				this.$refs[formName].validate(valid => {
					if (valid) {
						
						let formData = Object.assign({}, this.form);
						formData.client = this.$store.state.client;
						if (2 == type) {//查询费率
							console.log('submitForm',this.dialogFormVisibleType,this.allChannel,this.allChannelList.length);
							if('3'==this.dialogFormVisibleType && !!this.allChannel && this.allChannelList.length>0){//查询全部渠道
								console.log('getAllChannelQueryRate');
								this.getAllChannelQueryRate(formData,this.allChannelList);
							} else {//查询单个渠道
								this.getQueryRate(formData);
							}
							
						} else if (1 == type) {//保存，新增，编辑，复制
							if (row) {
								//指定特定渠道
								let channel = row.channel;
								if (channel) {
									formData.channel = channel;
								}
								//fedex cope需要传的字段
								let rateId = row.rateId;
								if (rateId) {
									formData.others.rateId = rateId;
								}
							}
							if(this.copy){
								formData.id = null;
							}
							if (formData.id == null) {
								//新增
								formData.id = null;
								this.postData(this.$urlConfig.WhFuerthertryAdd, [formData],'', (data) => {
									this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
									console.log('this.form.id', data);
									this.dialogFormVisible = false;
									// this.getPageData();
									this.$emit('success')
								});
							} else {
								//编辑
								// if(this.copy){
								// 	formData.id = null;
								// }
								this.postData(this.$urlConfig.WhFuerthertryEdit, formData,'', () => {
									this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
									this.dialogFormVisible = false;
									// this.getPageData();
									this.$emit('success')
								});
							}
						} else if(3 == type){//运费试算
							this.postData(this.$urlConfig.WhCarrFeePriceTestClac, formData, '', (data) => {
								this.queryFeeRateResClac = data.data;
								this.$message.success('查询成功');
							});
						} else if(4 == type){//运费比价
							this.postData(this.$urlConfig.WhCarrFeePriceComparePrice, formData, '', (data) => {
								if(data.data && data.data.carrierPriceArr){
									this.queryFeeRateResAll = data.data.carrierPriceArr;
									this.$message.success('查询成功');
								} else {
									this.$message.warning('查询失败');
								}
							});
						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('i18nn_b9221c0e13214d9c'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			
			//查询费率
			getQueryRate(filterData) {
				this.queryFeeRateRes = [];
				filterData.client = this.$store.state.client;
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhFuerthertryQueryRate, filterData)
					.then(({
						data
					}) => {
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							//表格显示数据
							// this.tableData = data.rows;
							this.queryFeeRateRes = data.rows;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.requestErrorData'));
						this.loading = false;
					});
			},
			//查询多个渠道费率
			async getAllChannelQueryRate (filterData,allChannelList) {
				filterData.client = this.$store.state.client;
				this.queryFeeRateRes = [];
				
				for(let i=0,ln=allChannelList.length;i<ln;i++){
					this.loading = true;
					let formData = filterData;
					formData.channel = allChannelList[i].key;
					console.log('formData',formData,i);
					let resData = await this.$http.put(this.$urlConfig.WhFuerthertryQueryRate, formData);
					console.log('resData',resData,i);
					let data = resData.data;
					if(200 == data.code && data.rows){
						this.queryFeeRateRes = this.queryFeeRateRes.concat(data.rows);
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
					}
					this.loading = false;
				}
				
			},
			//校验地址
			checkAddress() {
				let filterData = this.form.receiverVo;
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhThridOrderInfoCheckAddr, filterData)
					.then(({
						data
					}) => {
						console.log(data);
						this.loading = false;
						if (200 == data.code) {
							//表格显示数据
							// this.tableData = data.rows;
							// this.queryFeeRateRes = data.data;
							this.$message.success("已校验！");
							this.form.receiverVo.addr_type = data.data.addr_type;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.requestErrorData'));
						this.loading = false;
					});
			},

			// getDetData(keyId) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhThirdWmsConfigQuery + "/" + keyId, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.form = data.data;
			// 				this.$nextTick(() => {
			// 					this.$refs.cusSelFuzzy2.init(this.form.userId);
			// 					this.$refs.whNoSelect.init(this.form.whNo);
			// 				});
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 		});
			// },

			//提交信息
			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
			// 			let formData = Object.assign({}, this.form);
			// 			// if(!!formData.id){
			// 			// 	this.postData(this.$urlConfig.WhRemoteAreaCodeConfigAdd, formData, '', () => {
			// 			// 		this.dialogFile = false;
			// 			// 		this.$emit('success');
			// 			// 	});
			// 			// } else {
			// 			this.postData(this.$urlConfig.WhCarrierShipFromAddrUpdate, formData, '', () => {
			// 				this.dialogFormVisible = false;
			// 				this.$emit('success');
			// 			});
			// 			// }

			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },
			
			//lgsCode改变
			lgsCodeChange(key) {
				this.form.channel = "";
				this.form.signature = "";
				this.selLgsData = this.selectOption.biz_wh_lgskey_code.find(item => {
					return key == item.code;
				});
				// this.getChanneByCodeData(key);
				//渠道代码
				if (!!this.selLgsData && !!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.channels) {
					this.selectOption.channelList = this.selLgsData.extendParamJson.channels;
				} else {
					this.selectOption.channelList = [];
				}
				//签名
				if (!!this.selLgsData && !!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.signatures) {
					this.selectOption.wh_signature_service = this.selLgsData.extendParamJson.signatures;
				} else {
					this.selectOption.wh_signature_service = [];
				}
				//cope_fedex 原-> biz_wh_lgskey_cope_fedex 发货地址不可编辑-
				// cope_ups   cope_usps
				// if('cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key){
				//限制发货地址
				// if (this.onlySenderLgsCode.includes(key)) {
					this.getCopeShipFrom(key);
				// }
				// 仅查询
				this.allChannelList = [];
				if('3'==this.dialogFormVisibleType && this.selLgsData && this.selLgsData.extendParamJson){
					this.allChannel = true;
					this.allChannelList = this.selLgsData.extendParamJson.channels;
				}
			},
			//cope改变
			ChangCopeShip(v) {
				
				this.form.senderVo.firstname = "";
				// this.form.senderVo.company = ship.name;
				// if (shipAddr.addresses && shipAddr.addresses1) {
				this.form.senderVo.addr1 = "";
				// }
				// if (shipAddr.addresses2) {
					this.form.senderVo.addr2 = "";
				// }
				this.form.senderVo.city = "";
				this.form.senderVo.province = "";
				this.form.senderVo.postcode = "";
				this.form.senderVo.country = "";
				this.form.senderVo.mobile = "";
				// this.form.senderVo.uuid = shipAddr.uuid;
				
				let ship = this.CopeShipfromList.find(item => {
					return item.name == v;
				});
				console.log('ship', ship);
				let shipAddr = ship.address;
			
				if (shipAddr) {
					this.form.senderVo.firstname = shipAddr.name;
					// this.form.senderVo.company = ship.name;
					// if (shipAddr.addresses && shipAddr.addresses1) {
					this.form.senderVo.addr1 = shipAddr.addresses1;
					// }
					if (shipAddr.addresses2) {
						this.form.senderVo.addr2 = shipAddr.addresses2;
					}
					this.form.senderVo.city = shipAddr.city;
					this.form.senderVo.province = shipAddr.provinceCode;
					this.form.senderVo.postcode = shipAddr.postalCode;
					this.form.senderVo.country = shipAddr.countryCode;
					this.form.senderVo.mobile = shipAddr.phone;
					// this.form.senderVo.uuid = shipAddr.uuid;
				}
			
			},
			//旧-->biz_wh_lgskey_cope_fedex 发货地址
			//新--> 'cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key
			//限制发货地址
			getCopeShipFrom(lgsCode) {
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhPriterOrderCopeShipfrom + "?lgsCode=" + lgsCode, {})
					.then(({
						data
					}) => {
						// console.log('查询cope_fedex，请求成功');
						// console.log(data);
						this.loading = false;
						if (200 == data.code) {
							this.CopeShipfromList = data.rows;
							if (this.CopeShipfromList[0] && this.CopeShipfromList[0].name) {
								this.selCopeShip = this.CopeShipfromList[0].name;
								this.ChangCopeShip(this.selCopeShip);
							}
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.errorData');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// console.log(error);
						this.loading = false;
						// console.log('查询cope_fedex接口，请求失败');
						this.$message.error(this.$t('tips.requestErrorData'));
					});
			},
			
			//lgscode
			getLGSByCodeData() {
				
				// this.selectOption.biz_wh_lgskey_code = [];
				
				// this.form.senderVo.firstname = "";
				// // this.form.senderVo.company = ship.name;
				// // if (shipAddr.addresses && shipAddr.addresses1) {
				// this.form.senderVo.addr1 = "";
				// // }
				// // if (shipAddr.addresses2) {
				// 	this.form.senderVo.addr2 = "";
				// // }
				// this.form.senderVo.city = "";
				// this.form.senderVo.province = "";
				// this.form.senderVo.postcode = "";
				// this.form.senderVo.country = "";
				// this.form.senderVo.mobile = "";
				
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse: '1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs，请求成功');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.selectOption.biz_wh_lgskey_code = data.rows;
						} else {
							if (!data.msg) {
								data.msg = 'lgs请求失败';
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						console.log('查询数据字典接口，请求失败');
						this.$message.error('lgs请求失败！');
					});
			},
			
			//查询FBA地址
			searchFbaAddress(type) {
				console.log('searchFbaAddress');
				let fbaAddrCode = "";
				if (1 == type) {
					fbaAddrCode = this.fbaAddrCode;
				} 
				// else if (2 == type) {
				// 	fbaAddrCode = this.fbaAddrCode2;
				// }
				if (!fbaAddrCode) {
					this.$message.warning("请输入FBA仓库号");
					return;
				}
				if (1 == type) {
					this.form.receiverVo.province = "";
					this.form.receiverVo.city = "";
					this.form.receiverVo.addr1 = "";
					this.form.receiverVo.addr2 = "";
					this.form.receiverVo.postcode = "";
					this.form.receiverVo.mobile = "";
					this.form.receiverVo.mail = "";
					this.form.receiverVo.company = "";
					this.form.receiverVo.firstname = "";
					this.form.receiverVo.lastname = "";
				} 
				// else if (2 == type) {
					// this.form.senderVo.province = "";
					// this.form.senderVo.city = "";
					// this.form.senderVo.addr1 = "";
					// this.form.senderVo.addr2 = "";
					// this.form.senderVo.postcode = "";
					// this.form.senderVo.mobile = "";
					// this.form.senderVo.mail = "";
					// this.form.senderVo.company = "";
					// this.form.senderVo.firstname = "";
					// this.form.senderVo.lastname = "";
				// }
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + fbaAddrCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							// this.form.country = addrData.country;
							// this.form.state = addrData.state;
							// this.form.city = addrData.city;
							// this.form.postalCode = addrData.zipCode;
							// this.form.addr = addrData.address;
							// this.form.phone = addrData.phone;
							let findWh = data.data;
							if (1 == type) {
								this.form.receiverVo.province = findWh.state;
								this.form.receiverVo.city = findWh.city;
								this.form.receiverVo.addr1 = findWh.address;
								this.form.receiverVo.addr2 = "";
								this.form.receiverVo.postcode = findWh.zipCode;
								this.form.receiverVo.mobile = findWh.phone;
								this.form.receiverVo.mail = "";
								this.form.receiverVo.company = "";
								this.form.receiverVo.firstname = findWh.name;
								this.form.receiverVo.lastname = "";
							} 
							// else if (2 == type) {
							// 	this.form.senderVo.province = findWh.state;
							// 	this.form.senderVo.city = findWh.city;
							// 	this.form.senderVo.addr1 = findWh.address;
							// 	this.form.senderVo.addr2 = "";
							// 	this.form.senderVo.postcode = findWh.zipCode;
							// 	this.form.senderVo.mobile = findWh.phone;
							// 	this.form.senderVo.mail = "";
							// 	this.form.senderVo.company = "";
							// 	this.form.senderVo.firstname = findWh.name;
							// 	this.form.senderVo.lastname = "";
							// }
			
						} else {
							// if (!data.msg) {
							// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
						// this.$message.error('查询失败!');
					});
			},
			
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);
			
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				Object.keys(this.form).forEach(key => {
					if (Object.prototype.toString.call(this.form[key]) === '[object Object]') {
						Object.keys(this.form[key]).forEach(key2 => {
							this.form[key][key2] = null;
						});
					} else {
						this.form[key] = null;
					}
				});
				// this.form.lgsKeyCode = "Furthertry";
				this.form.receiverVo.country = 'US';
				this.form.senderVo.country = 'US';
			
				// this.form.packageVo.sizUnit = '1';
				this.form.others.label_size = '1';
			
				this.queryFeeRateRes = [];
				
				this.fbaAddrCode ="";
				this.fbaAddrCode2 ="";
				this.$nextTick(()=>{
					this.$refs.whNoAllSelect.init("");
					// this.$refs.whNoAllSelect2.init("");
				})
				// console.log();
				// this.CateValue = [];
				// if (this.$refs.hyUpLoadImg1) {
				// 	this.$refs.hyUpLoadImg1.initUrl('');
				// }
			},

			//提交信息
			postData(url, formData, type,callback) {
				// let _this = this;
				this.loading = true;
				let http = {};
				if ('delete' == type) {
					http = this.$http.delete(url, formData);
				} else {
					http = this.$http.put(url, formData);
				}
				http
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
			
						this.loading = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.submitError');
							}
							this.$alert(data.msg, this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>